<template>
    <v-container fluid grey darken-4>
        <div id="yellowStripe" >
            <div class="ml-2" id="postBackground">
                <v-img 
                :src="post.image"
                max-height="360px"
                max-width="100%">
                </v-img>
                <div id="text" class="px-5 pb-5">
                <h1 class="pt-2 pb-5">{{ post.title}}</h1>
                <p>{{post.text}}</p>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import axios from 'axios'
import {api} from '../settings.js'
export default{
    props: ['id'],
    data(){
        return{
            post: {} 
            }
    },
    mounted(){
        axios.get(api + '/lifestyleBlog/blogposts/' + this.id).then(response => {
            this.post = response.data;
            this.post.image = api + this.post.image;
        })
    }
}
</script>

<style scoped>
#postBackground{
    background-color: #d8d8d8;
}

#yellowStripe{
    background-color: #fff200;
    width: 100%;
}

p{
    font-size: 24px;
}
</style>